import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import BlockModal from '../BlockModal';
import * as action from '../../redux/auth/actions';
import './login-modal.scss';
import {
  fetchClient,
  formatPhoneNumber,
  toastrError,
  toastrSuccess,
  validateEmail,
  validatePhone,
} from '../../redux/Helpers';
import {
  GET_SOCIAL_MEDIA_CLIENT_IDS,
  POST_CHECK_VALID_EMAIL,
  VERIFY_SMS,
  VERIFY_SMS_GUEST,
  REQUEST_MAGIC_LINK,
} from '../../config';
import { Link, useHistory } from 'react-router-dom';
import AdaptiveInput from '../CheckoutForm/AdaptiveInput';
import cookies from '../../redux/Helpers/cookies';
import { isMobile } from '../../DetectScreen';
import Select from 'react-dropdown-select';
import { toLower, trim } from 'lodash';
import DotLoader from '../DotLoader';
import SMSVerify from './SMSVerify';
import axios from 'axios';
import { setCheckoutV2StateAction } from '../../redux/checkoutV2/actions';
import successful from '../../images/icons/successful-payment.svg';

function LoginModal(props: any): JSX.Element {
  const willNotLoginAfterRegister: any = props?.willNotLoginAfterRegister;
  const dispatch = useDispatch();
  const [successMessage] = useState('');
  const [noInput, setNoInput] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<any>({
    email: '',
    username: '',
    password: '',
    password_confirm: '',
    phone: props?.phone || '',
    firstName: '',
    lastName: '',
    birthday: '',
    gender: '',
    newsletter_email: true,
    newsletter_sms: true,
  });
  const stateUser = useSelector((state: RootStateOrAny) => state.userReducer);
  const [validEmail, setValidEmail] = useState<any>(
    props?.validEmail === false ? false : true,
  );
  const [validateForm, setValidateForm] = useState<any>({
    email: '',
    username: '',
    password: '',
    password_confirm: '',
    phone: '',
    firstName: '',
    lastName: '',
    birthday: '',
    gender: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [openSMSVerify, setOpenSMSVerify] = useState<boolean>(false);
  const islogged = localStorage.getItem('sundoraToken') && cookies.get('token');
  const [passwordLessLoginSent, setPasswordLessLoginSent] = useState<boolean>(false);
  const checkValidEmail = async () => {
    const validCheckEmail = validateEmail(dataForm.email.trim());
    const validCheckPhone = validatePhone(dataForm.email.trim());

    if (!validCheckEmail && validCheckPhone) {
      setDataForm((prev: any) => ({
        ...prev,
        phone: dataForm.email.trim(),
      }));
    }

    if (!validCheckEmail && !validCheckPhone) {
      return `Please enter valid email or phone`;
    }

    const options = {
      url: POST_CHECK_VALID_EMAIL,
      method: 'POST',
      body: {
        email: (dataForm.email as string).toLowerCase().trim(),
      },
    };

    const res = await fetchClient(options);
    setValidEmail(res?.success);
    if (!validEmail && res?.success) {
      return `Please choose another email`;
    }

    if (!validCheckEmail) {
      return `Please enter valid email`;
    }

    return res?.success;
  };

  const checkValidPassword = () => {
    if (!dataForm.password || dataForm?.password == '') {
      return 'Please enter password';
    }
    const validPassword = dataForm.password.length >= 8;

    if (validPassword == false) {
      return `Please enter a password of more than 8 characters`;
    }
  };

  const checkValidPasswordCofirm = () => {
    if (dataForm?.password != dataForm?.password_confirm) {
      return 'Confirm password is not known correctly';
    }
  };

  const checkValidPhone = async () => {
    if (!dataForm.phone || dataForm?.phone == '') {
      return 'Please enter phone number';
    }
    const phone = formatPhoneNumber(dataForm.phone);
    const validPhone = validatePhone(phone);

    if (validPhone == false) {
      return `Please enter valid phone number`;
    }

    const options = {
      url: POST_CHECK_VALID_EMAIL,
      method: 'POST',
      body: {
        email: phone,
      },
    };
    try {
      const response = await fetchClient(options);
      if (response.success) {
        return `Please choose another phone number`;
      }
    } catch (error) {
      //
    }
  };

  const checkValidFirstName = () => {
    if (!dataForm.firstName || dataForm?.firstName == '') {
      return 'Please enter first name';
    }
  };

  const checkValidLastName = () => {
    if (!dataForm.lastName || dataForm?.lastName == '') {
      return 'Please enter last name';
    }
  };

  const checkValidForm = async () => {
    const validEmail = await checkValidEmail();
    const validPassword = checkValidPassword();
    const validPasswordConfirm = checkValidPasswordCofirm();
    const validFirstName = checkValidFirstName();
    const validLastName = checkValidLastName();
    const validPhone = await checkValidPhone();
    setValidateForm({
      ...validateForm,
      ...{
        email: validEmail,
        password: validPassword,
        password_confirm: validPasswordConfirm,
        firstName: validFirstName,
        lastName: validLastName,
        phone: validPhone,
      },
    });

    if (
      validEmail ||
      validPassword ||
      validPasswordConfirm ||
      validFirstName ||
      validLastName ||
      validPhone
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isCheckbox = false) => {
    setNoInput(false);
    let value: any = event.target.value;
    if (isCheckbox) value = event.target.checked;
    setDataForm({ ...dataForm, [event.currentTarget.name]: value });
  };

  const handleChangeSelect = (keyName: any, value: any) => {
    setDataForm({
      ...dataForm,
      [keyName]: trim(toLower(value[0].name)),
    });
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const draft_order_id = localStorage.getItem('id_checkout')
      ? localStorage.getItem('id_checkout')
      : null;
    dispatch(
      action.loginActions({
        email: (dataForm.email as string).toLowerCase(),
        password: dataForm.password,
        draft_order_id: draft_order_id,
      }),
    );
  };

  const handleregister = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const valid = await checkValidForm();
    if (valid) {
      setLoading(false);
      return false;
    }
    const draft_order_id = localStorage.getItem('id_checkout')
      ? localStorage.getItem('id_checkout')
      : null;
    dispatch(
      action.registerActions({
        customer: {
          email: dataForm.email ? (dataForm.email as string).toLowerCase() : null,
          password: dataForm.password,
          password_confirmation: dataForm.password,
          phone: formatPhoneNumber(dataForm.phone),
          draft_order_id: draft_order_id,
          first_name: dataForm.firstName,
          last_name: dataForm.lastName,
          note: {
            birthday: dataForm.birthday,
            gender: dataForm.gender,
          },
          withTimeout: true,
          newsletter_email: dataForm.newsletter_email,
          newsletter_sms: dataForm.newsletter_sms,
        },
      }),
    );

    setOpenSMSVerify(true);
  };

  const handelToggleModal = () => {
    dispatch(action.toggleModalLoginActions(false));
  };

  const handleKeyPress = () => {
    dispatch(action.toggleModalLoginActions(false));
  };
  const history = useHistory();
  const directForgotPassword = () => {
    dispatch(action.toggleModalLoginActions(false));
    history.push('/forgot-password');
  };

  useEffect(() => {
    const options = {
      url: GET_SOCIAL_MEDIA_CLIENT_IDS,
      method: 'GET',
      body: null,
    };
    fetchClient(options).then((res) => {
      if (res?.success) {
      }
    });
  }, []);

  useEffect(() => {
    if (stateUser.loginRequestStatus.error) {
      setLoading(false);
    }
  }, [stateUser.loginRequestStatus.error]);

  const renderLoginError = () => {
    if (noInput) {
      return <p className="err-login">Please enter your email or phone number</p>;
    }
    if (
      validEmail &&
      !stateUser.loginRequestStatus.inProgress &&
      stateUser.loginRequestStatus.error
    ) {
      if (stateUser.loginRequestStatus.exists && !stateUser.loginRequestStatus.email_verified)
        return (
          <p className="err-login">
            Email not verified, new magic link has been sent to your email
          </p>
        );
      else if (
        stateUser.loginRequestStatus.exists &&
        !stateUser.loginRequestStatus.sms_verified
      ) {
        setOpenSMSVerify(true);
      } else return <p className="err-login">Wrong username or password</p>;
    }
    return null;
  };

  const handleVerifySMS = (data: any) => {
    console.log('handleVerifySMS', data);
    axios
      .post(dataForm?.guest ? VERIFY_SMS_GUEST : VERIFY_SMS, data)
      .then((res: any) => {
        dispatch(
          setCheckoutV2StateAction({
            smsVerifyAsGuest: true,
            guestPhone: dataForm?.phone,
          }),
        );
        toastrSuccess('SMS verification successful');
        toastrSuccess('Registration Successful');
        setOpenSMSVerify(false);

        if (res?.data?.data) {
          !willNotLoginAfterRegister &&
            dispatch(action.verificationSuccessActions(res.data?.data));
        }
      })
      .catch(() => {
        toastrError('Code incorrect or expired');
      });
  };

  const requestPasswordlessLooginLink = () => {
    setNoInput(false);
    const valid = checkValidEmail();
    if (!valid || dataForm.email.length < 1) {
      setNoInput(true);
      return;
    } else {
      setLoading(true);
      const options = {
        url: REQUEST_MAGIC_LINK,
        method: 'POST',
        body: {
          email: (dataForm.email as string)?.toLowerCase(),
        },
      };
      fetchClient(options).then((res) => {
        setLoading(false);
        if (res?.success) {
          console.log(res?.success);
          setPasswordLessLoginSent(true);
        } else if (res?.isError) {
          setNoInput(true);
        }
      });
    }
  };

  return (
    <section className="loginModal">
      <BlockModal
        isOpen={stateUser.modalLogin}
        minWidth={isMobile ? '70%' : '36%'}
        maskBg={true}
        onKeyPress={!successMessage?.length ? () => handleKeyPress() : () => null}
        onClickAway={!successMessage?.length ? () => handelToggleModal() : () => null}
        showCloseButton={!successMessage?.length}
      >
        {loading ? '' : ''}
        {passwordLessLoginSent ? (
          <div
            className="wrapper-form"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={successful}
              alt=""
              style={{
                width: '80px',
                marginBottom: '16px',
              }}
            />
            <h3>Passwordless Login Link Sent</h3>
            <h4>
              A temporary login link was sent to your email address. Please click the link in
              that message to login. You can update your password once logged in.
            </h4>
          </div>
        ) : (
          <div className="wrapper-form">
            {!successMessage?.length && !openSMSVerify ? (
              <h2 className={`title-form ${islogged ? 'logged' : ''}`}>
                {validEmail ? 'SIGN IN' : 'REGISTER'}
              </h2>
            ) : openSMSVerify ? (
              <h2 className={`title-form`}>OTP Verification</h2>
            ) : null}

            {openSMSVerify && (
              <SMSVerify
                dataForm={dataForm}
                setOpenSMSVerify={setOpenSMSVerify}
                handleVerify={handleVerifySMS}
              />
            )}

            {islogged || successMessage?.length || openSMSVerify ? (
              ''
            ) : (
              <>
                <div className={`${!validEmail ? 'd-flex-form' : ''}`}>
                  <div className="form-group" style={{ marginBottom: '24px' }}>
                    <label htmlFor="phone" className="animated faster fade-in">
                      {validEmail ? (
                        <>
                          Email or phone number<span className="required">*</span>
                        </>
                      ) : (
                        <>
                          Email<span className="required">*</span>
                        </>
                      )}
                    </label>
                    <AdaptiveInput
                      type="text"
                      for="email"
                      name="email"
                      label={`Enter email ${validEmail ? ' or phone number' : ''}`}
                      value={dataForm.email}
                      handleChange={handleChange}
                      validate={validEmail ? false : true}
                      validateContent={validateForm.email}
                      handleBlurInput={checkValidEmail}
                    />
                  </div>
                  {!validEmail ? (
                    <div className="form-group phone-input">
                      <label htmlFor="phone" className="animated faster fade-in">
                        Phone number<span className="required">*</span>
                      </label>
                      <AdaptiveInput
                        type="text"
                        for="Phone number"
                        label="Phone number"
                        name="phone"
                        value={dataForm.phone}
                        validate={true}
                        validateContent={validateForm.phone}
                        handleChange={handleChange}
                      />
                      <div
                        className="input-description"
                        style={{ fontSize: '0.7em', lineHeight: '190%' }}
                      >
                        An OTP will be sent to your number
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={`${!validEmail ? 'd-flex-form' : ''}`}>
                  <div className="form-group" style={{ marginBottom: '24px' }}>
                    {validEmail ? (
                      <label htmlFor="password" className="animated faster fade-in">
                        Enter your password to login
                      </label>
                    ) : (
                      <label htmlFor="password" className="animated faster fade-in">
                        Password<span className="required">*</span>
                      </label>
                    )}

                    <AdaptiveInput
                      type="password"
                      for="password"
                      name="password"
                      label="Enter password"
                      value={dataForm.password}
                      validate={true}
                      validateContent={validateForm.password}
                      handleChange={handleChange}
                    />
                  </div>
                  {!validEmail ? (
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="password_confirm" className="animated faster fade-in">
                        Password confirm<span className="required">*</span>
                      </label>

                      <AdaptiveInput
                        type="password"
                        for="password_confirm"
                        name="password_confirm"
                        label="Enter password confirm"
                        value={dataForm.password_confirm}
                        validate={true}
                        validateContent={validateForm.password_confirm}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {!validEmail ? (
                  <div className="d-flex-form">
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="first_name" className="animated faster fade-in">
                        First name<span className="required">*</span>
                      </label>
                      <AdaptiveInput
                        type="text"
                        for="first_name"
                        name="firstName"
                        label="Enter first name"
                        value={dataForm.firstName}
                        validate={true}
                        validateContent={validateForm.firstName}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="last_name" className="animated faster fade-in">
                        Last name<span className="required">*</span>
                      </label>
                      <AdaptiveInput
                        type="text"
                        for="last_name"
                        name="lastName"
                        label="Enter last name"
                        value={dataForm.lastName}
                        validate={true}
                        validateContent={validateForm.lastName}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="last_name" className="animated faster fade-in">
                        Enter your birthday - Optional
                      </label>
                      <AdaptiveInput
                        type="date"
                        for="birthday"
                        name="birthday"
                        label=""
                        value={dataForm.birthday}
                        validate={true}
                        validateContent={validateForm.birthday}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="form-group select-group">
                      <label htmlFor={'gender'} className="text-help animated faster fade-in">
                        Enter gender - Optional
                      </label>
                      <Select
                        placeholder="Select"
                        className="primary-select"
                        name="gender"
                        required={false}
                        searchable={false}
                        labelField="name"
                        onChange={(e: any) => {
                          handleChangeSelect('gender', e);
                        }}
                        valueField="name"
                        options={[
                          { id: 1, name: 'Other' },
                          { id: 2, name: 'Male' },
                          { id: 3, name: 'Female' },
                        ]}
                        multi={false}
                        values={[]}
                      />
                    </div>
                    <div className="newsletter">
                      <label className="checkbox-button" htmlFor="newsletter_email">
                        <input
                          type="checkbox"
                          className="checkbox-button__input"
                          id="newsletter_email"
                          name="newsletter_email"
                          onChange={(e) => handleChange(e, true)}
                          checked={dataForm.newsletter_email}
                        />
                        <div className="checkbox-button__control"></div>
                      </label>
                      <label className="checkbox-button__label" htmlFor="newsletter_email">
                        I consent to Sundora and its service providers, sending me marketing
                        information and materials of Sundora&apos;s and its partners&apos;
                        products, services and events, by email and SMS
                      </label>
                      &nbsp;
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {renderLoginError()}
                {stateUser.loginRequestStatus.inProgress || loading ? <DotLoader /> : null}
                {validEmail ? (
                  <div
                    className="submit-box"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      alignContent: 'space-between',
                      alignItems: 'stretch',
                    }}
                  >
                    <button
                      className="btn-primary"
                      style={{ cursor: 'pointer', margin: '10px' }}
                      onClick={(event) => handleLogin(event)}
                      disabled={stateUser.loginRequestStatus.inProgress}
                    >
                      SIGN IN
                    </button>
                    <button
                      className="btn-primary"
                      style={{ cursor: 'pointer', margin: '10px' }}
                      onClick={requestPasswordlessLooginLink}
                    >
                      SIGN IN WITHOUT PASSWORD
                    </button>
                    {validEmail ? (
                      <div
                        style={{
                          flexBasis: '100%',
                          marginLeft: 'auto',
                          alignSelf: 'flex-end',
                          textAlign: 'right',
                          marginRight: '10px',
                        }}
                      >
                        {' '}
                        Don&apos;t have an account?{' '}
                        <button
                          className="btn-forgot-password"
                          onClick={() => setValidEmail(false)}
                        >
                          Register
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="submit-box" style={{ flexWrap: 'wrap' }}>
                    <button
                      className="btn-forgot-password"
                      onClick={() => setValidEmail(true)}
                    >
                      Sign in
                    </button>
                    <button className="btn-primary" onClick={(event) => handleregister(event)}>
                      REGISTER
                    </button>
                    <div className="terms-cond">
                      By signing up you confirm that you have read and accepted our{' '}
                      <Link to={'/about-us/terms-conditions'} onClick={handelToggleModal}>
                        {' '}
                        Terms & conditions
                      </Link>{' '}
                      and{' '}
                      <Link to={'/about-us/privacy-policy'} onClick={handelToggleModal}>
                        Privacy Policy
                      </Link>
                    </div>
                    {validEmail ? (
                      <div
                        style={{
                          marginLeft: 'auto',
                          alignSelf: 'flex-end',
                          textAlign: 'right',
                          fontSize: 14,
                          marginTop: '1rem',
                        }}
                      >
                        <a
                          className="btn-forgot-password"
                          style={{ cursor: 'pointer' }}
                          onClick={directForgotPassword}
                        >
                          Forgot password?
                        </a>
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </BlockModal>
    </section>
  );
}
export default LoginModal;
